export const SPOT_STATUS_DRAFT = "DRAFT";
export const SPOT_STATUS_ACTIVE = "ACTIVE";
export const SPOT_STATUS_INACTIVE = "INACTIVE";

export const spotStatusOptions = {
  [SPOT_STATUS_DRAFT]: "DRAFT",
  [SPOT_STATUS_ACTIVE]: "ACTIVE",
  [SPOT_STATUS_INACTIVE]: "INACTIVE",
};

export const CONDITION_CLEAN = "CLEAN";
export const CONDITION_DIRTY = "DIRTY";

export const DUPLICATED_DEFAULT_CODE = "ZYX";
