export const TENT = "TENT";
export const TENT_TRAILER = "TENT_TRAILER";
export const TRAILER = "TRAILER";
export const VAN = "VAN";
export const MOTORHOME = "MOTORHOME";

export const allowedEquipmentsOptions = [
  TENT,
  TENT_TRAILER,
  TRAILER,
  VAN,
  MOTORHOME,
];
