<template>
  <el-select
    v-model="model"
    @change="valueChanged"
    collapse-tags
    :placeholder="placeholder ?? $t('SPOTS.ALLOWED_EQUIPMENT')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="option in options"
      :key="option"
      :value="option"
      :label="$t(`SPOTS.ALLOWED_EQUIPMENT_${option}`)"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { allowedEquipmentsOptions } from "@/constants/allowedEquipments";

export default {
  name: "camping-equipment-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: false,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    value: {
      type: null | undefined | String | Number | Array,
      default: null,
      description: "value",
    },
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder",
    },
    optionsValue: {
      type: Array,
      description: "Options",
    },
  },

  data() {
    return {
      model: this.value,
      options: this.optionsValue ?? allowedEquipmentsOptions,
    };
  },

  setup() {},

  methods: {
    valueChanged(value) {
      this.$emit("valueChanged", value);
    },
  },

  watch: {
    value(value) {
      this.model = value;
    },
  },
};
</script>
