export const REASON_REPAIR = "REPAIR";
export const REASON_UNAVAILABLE = "UNAVAILABLE";

export const outOfServiceReasonOptions = [REASON_REPAIR, REASON_UNAVAILABLE];

export const STATUS_OUT_OF_SERVICE = 'OUT_OF_SERVICE';

export const STATUS_DRAFT = 'DRAFT';

export const STATUS_GUARANTEED = 'GUARANTEED';

export const STATUS_NOT_GUARANTEED = 'NOT_GUARANTEED';

export const STATUS_CANCELED = 'CANCELED';

export const STATUS_ARRIVED = 'ARRIVED';

export const STATUS_FINISHED = 'FINISHED';

export const STATUS_BLOCKED = 'BLOCKED';

export const statusOptions = {
    [STATUS_DRAFT]: STATUS_DRAFT,
    [STATUS_GUARANTEED]: STATUS_GUARANTEED,
    [STATUS_NOT_GUARANTEED]: STATUS_NOT_GUARANTEED,
    [STATUS_ARRIVED]: STATUS_ARRIVED,
    [STATUS_FINISHED]: STATUS_FINISHED,
} ;