<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left booking">
          <div class="kw-back">
            <h3 class="mb-0">{{ $t("BOOKINGS.PENDING_BOOKING_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("BOOKINGS.BOOKING_LIST") }}</span>
              </li>
              <li>
                <span>{{ $t("BOOKINGS.PENDING_BOOKING_LIST") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- region action control -->
        <div class="page-wrapper-header-right">
          <router-link
            v-if="$currentUserCan($permissions.PERM_CREATE_BOOKINGS)"
            :to="{ name: 'New Booking' }"
            v-slot="{ href, navigate }"
            custom
          >
            <base-button
              :href="href"
              @click="navigate"
              class="elite-button add"
              icon
              size="sm"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("BOOKINGS.ADD_BOOKING") }}
              </span>
            </base-button>
          </router-link>
        </div>
        <!-- endregion -->
      </div>

      <div class="pending-booking">
        <h1>
          <img src="/img/kw-shopping-cart.svg" alt="icon" />
          {{ $t("BOOKINGS.PENDING_BOOKING_LIST") }}
        </h1>

        <el-tabs class="pending-booking-list" type="card">
          <el-tab-pane :label="$t('BOOKINGS.MY_CURRENT_BOOKINGS')">
            <pending-bookings
              only-current-user-bookings
              @onConfirmOrder="openOrderConfirmModal"
              @onDeleteOrder="deleteBooking"
              @onEditBooking="editBooking"
              @onNewBooking="newBooking"
              @onCloseBookingModal="closeBookingModal"
              ref="componentPendindBookings1"
              :key="renderKey * 100"
            ></pending-bookings>
          </el-tab-pane>
          <el-tab-pane :label="$t('BOOKINGS.OTHERS_CURRENT_BOOKINGS')">
            <pending-bookings
              @onDeleteOrder="deleteBooking"
              @onEditBooking="editBooking"
              @onNewBooking="newBooking"
              ref="componentPendindBookings2"
              :key="renderKey * 200"
            ></pending-bookings>
          </el-tab-pane>
        </el-tabs>

        <div
          v-if="isConfirmBookingModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isConfirmBookingModalOpened ? 'show' : 'hide']"
          :key="renderKey * 400"
        >
          <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <h1>
                  {{ $t("BOOKINGS.CONFIRM_BOOKING") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <button class="close" @click="closeBookingModal">
                  <i class="fal fa-times"></i>
                </button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <confirm-booking-component
                v-if="openOrder"
                @onCloseBookingModal="closeBookingModal"
                :sales-order-id="openOrder.id"
              />
            </div>
          </div>
        </div>

        <div
          v-if="modalEditBooking"
          class="resizable-wrapper"
          :class="[modalEditBooking ? 'show' : 'hide']"
          :key="renderKey * 400"
        >
          <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeEditBookingModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("BOOKINGS.EDIT_BOOKING") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button />
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <edit-booking-component
                v-if="modalEditBooking"
                :sales-order-id="selectedSalesOrder.id"
                @bookingUpdated="handleAfterBookingUpdated"
                @customerUpdated="handleAfterBookingUpdated"
                @closeBookingModal="closeBookingModal"
                @onSalesOrderEditionCancelled="closeEditBookingModal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import {Button, TabPane, Tabs} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ConfirmBookingComponent from "./components/ConfirmBookingComponent.vue";
import EditBookingComponent from "./components/EditBookingComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton.vue";
import PendingBookings from "./partials/pendingBookings/PendingBookings.vue";
import {mapGetters} from "vuex";

const QUERY_ACTIONS_CONFIRM = "confirm";

export default {
  layout: "DashboardLayout",

  components: {
    PendingBookings,
    ConfirmBookingComponent,
    EditBookingComponent,
    WrapperExpandButton,
    [Button.name]: Button,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  watch: {},

  data() {
    const orderId = this.$route.query.orderId;
    const action = this.$route.query.action;
    let isConfirmBookingModalOpened = false;
    let openOrder = null;

    if (orderId && action === QUERY_ACTIONS_CONFIRM) {
      isConfirmBookingModalOpened = true;
      openOrder = { id: orderId };
    }

    return {
      isConfirmBookingModalOpened,
      openOrder,
      renderKey: 1,
      modalEditBooking: false,
      selectedSalesOrder: null,
      isBookingUpdated: false
    };
  },

  methods: {
    openOrderConfirmModal(order) {
      this.closeBookingModal();
      this.isConfirmBookingModalOpened = true;
      this.openOrder = order;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "Pending Bookings",
          query: { action: QUERY_ACTIONS_CONFIRM, orderId: this.openOrder.id },
        }).href
      );
    },

    closeBookingModal(reRender = false) {
      this.isConfirmBookingModalOpened = false;
      this.modalEditBooking = false;
      this.openOrder = null;

      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "Pending Bookings",
          query: {},
        }).href
      );
    },

    async deleteBooking(order) {
      const confirmation = await swal.fire({
        text: this.$t("BOOKINGS.DELETE_THIS_BOOKING"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          order.items.forEach((item) => {
            this.$store.dispatch("salesOrderItems/destroy", item.id);
            this.$store.dispatch("bookings/destroy", item.salesOrderable.id);
          });
          await this.$store.dispatch("salesOrders/destroy", order.id);

          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editBooking(order)
    {
      if (order.updatedBy.id === this.me.id) {
        this.selectedSalesOrder = order;
        this.modalEditBooking = true;
        return false;
      }

      const confirmation = await swal.fire({
        html: this.$t("BOOKINGS.TAKE_CONTROL_OF_BOOKING", {
          email: order.recipient.email,
        }),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.value === true) {
        return this.$router.push({
          name: "New Booking",
          query: { spotId: booking.spot.id, action: "add" },
        });
      }
    },

    newBooking(customer) {
      return this.$router
        .push({
          name: "New Booking",
          query: { customerId: customer?.id },
        })
        .catch((err) => console.error(err));
    },

    async closeEditBookingModal()
    {
      this.modalEditBooking = false;
      this.selectedSalesOrder = null;
      if(this.isBookingUpdated)
      {
        await this.$refs.componentPendindBookings1.getList();
        await this.$refs.componentPendindBookings2.getList();
        this.renderKey++;
        this.isBookingUpdated = false ;
      }
    },

    handleAfterBookingUpdated() {
      this.isBookingUpdated = true ;
    },
  },
};
</script>
