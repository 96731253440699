export const ORDER_PERIOD_TODAY = "TODAY";
export const ORDER_PERIOD_YESTERDAY = "YESTERDAY";
export const ORDER_PERIOD_CURRENT_WEEK = "CURRENT_WEEK";
export const ORDER_PERIOD_CURRENT_MONTH = "CURRENT_MONTH";
export const ORDER_PERIOD_LAST_THREE_MONTH = "LAST_THREE_MONTHS";
export const ORDER_PERIOD_LAST_SEVEN_DAYS = "LAST_SEVEN_DAYS";

export const ORDER_PERIOD = [
  ORDER_PERIOD_TODAY,
  ORDER_PERIOD_YESTERDAY,
  ORDER_PERIOD_LAST_SEVEN_DAYS,
  ORDER_PERIOD_CURRENT_WEEK,
  ORDER_PERIOD_CURRENT_MONTH,
  ORDER_PERIOD_LAST_THREE_MONTH,
];
