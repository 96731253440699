<!-- On transmet ici non pas la reservation mais un SalesOrder -->
<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!this.salesOrder || this.loading">
      <span class="loader"></span>
    </span>
    <div v-if="this.salesOrder" class="edit-booking">
      <div class="edit-booking-collapse">
        <el-collapse accordion value="0">
          <!-- region facturation et reservation -->
          <el-collapse-item name="1">
            <template slot="title">
              <h3>
                {{ $t("BOOKINGS.BOOKING_INFORMATION") }}
              </h3>
            </template>
            <!-- Les informations de la réservation -->
            <div v-if="!this.selectedBooking">
              <booking-card
                v-for="(item, i) in this.salesOrder.items"
                :key="i"
                :orderItem="item"
                :booking="item.salesOrderable"
                @onEditBooking="editBooking"
              />
            </div>
            <!-- Le formulaire de modification -->
            <div class="booking-update">
              <edit-booking-form
                v-if="this.selectedBooking"
                @onBookingEditionDone="onBookingEditionDone"
                @onBookingEditionCancelled="onBookingEditionCancelled"
                :booking-data="this.selectedBooking"
              >
              </edit-booking-form>
            </div>
            <!-- La facture ici -->
            <div v-if="!this.selectedBooking" class="invoice">
              <div class="invoice-title">
                <h3>{{ $t("APPS.INVOICING_MODULE") }}</h3>
              </div>
              <div class="invoice-list">
                <dl v-for="(item, index) in this.salesOrder.items" :key="index">
                  <dt>
                    {{ $t("BOOKINGS.TITLE") }}
                    {{ item.salesOrderable.code }}
                  </dt>
                  <dd>{{ item.pricing.subtotal }} CAD</dd>
                </dl>
              </div>
              <!-- Sous total -->
              <div class="invoice-subtotal">
                <dl>
                  <dt>
                    {{ $t("COMMON.SUBTOTAL") }}
                  </dt>
                  <dd>{{ this.salesOrder.pricing.subtotal }} CAD</dd>
                </dl>
              </div>
              <!-- Autre et taxes -->
              <div class="invoice-others">
                <dl>
                  <dt>TPS</dt>
                  <dd>5.08 CAD</dd>
                </dl>
                <dl>
                  <dt>TVQ</dt>
                  <dd>12,14 CAD</dd>
                </dl>
                <dl>
                  <dt>Autres</dt>
                  <dd>80 CAD</dd>
                </dl>
              </div>
              <div class="invoice-total">
                <dl>
                  <dt>
                    {{ $t("COMMON.TOTAL") }}
                  </dt>
                  <dd>{{ this.salesOrder.pricing.total }} CAD</dd>
                </dl>
              </div>
            </div>
          </el-collapse-item>
          <!-- endregion -->

          <!-- region client -->
          <el-collapse-item name="2">
            <template slot="title">
              <h3>
                {{ $t("CUSTOMERS.VIEW_CUSTOMER") }}
              </h3>
            </template>
            <div v-if="this.customer && !customerStep" class="customer">
              <div class="customer-details">
                <dl>
                  <dt>
                    <strong>{{ $t("CUSTOMERS.LAST_NAME") }}</strong>
                  </dt>
                  <dd>
                    {{ this.customer.firstname }} {{ this.customer.lastname }}
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <strong>{{ $t("CUSTOMERS.EMAIL_SHORT") }}</strong>
                  </dt>
                  <dd>
                    {{ this.customer.email }}
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <strong>{{ $t("CUSTOMERS.PHONE_SHORT") }}</strong>
                  </dt>
                  <dd>
                    {{ this.customer.phone }}
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <strong>{{ $t("COMMON.CITY") }}</strong>
                  </dt>
                  <dd>
                    {{ this.customer.city }}
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <strong>{{ $t("COMMON.COUNTRY") }}</strong>
                  </dt>
                  <dd>
                    {{ this.customer.country }}
                  </dd>
                </dl>
              </div>
              <!-- region Boutons pour modifier le client -->
              <div class="customer-buttons">
                <el-button @click="customerStep = true">
                  {{ $t("COMMON.UPDATE") }}
                </el-button>
                <el-button type="success" v-if="false">
                  {{ $t("COMMON.ADD") }}
                </el-button>
              </div>
              <!-- endregion -->
            </div>
            <!-- formulaire de modification du client -->
            <div class="customer-update">
              <edit-booking-customer
                v-if="customerStep"
                @onEditBookingCustomerDone="onEditBookingCustomerDone"
                @onEditBookingCustomerCancelled="onEditBookingCustomerCancelled"
                :sales-order-organization-id="this.salesOrder.organization.id"
                :is-order-draft="this.salesOrder.status === 'DRAFT'"
                :customer-data="this.customer"
              >
              </edit-booking-customer>
            </div>
          </el-collapse-item>
          <!-- endregion -->

          <!-- region moyen de paiement -->
          <el-collapse-item name="3">
            <template slot="title">
              <h3>
                {{ $t("CUSTOMERS.PAYMENT_MODE") }}
              </h3>
            </template>

            <div v-if="this.customer" class="payment">
              <div class="payment-title">
                <div class="payment-title-left">
                  <h4>
                    {{ $t("CUSTOMERS.PAYMENT_MODE") }}
                  </h4>
                  <p>
                    {{ $t("CUSTOMERS.PAYMENT_METHODS_MANAGE") }}
                  </p>
                </div>
                <div class="payment-title-right">
                  <el-button type="success">
                    {{ $t("CUSTOMERS.PAYMENT_METHODS_PAYMENTS") }}
                  </el-button>
                </div>
              </div>
              <div class="payment-cards">
                <credit-card-selector
                  v-if="this.customer"
                  :customer-id="this.customer.id"
                  v-model="this.selectedCard"
                  @creditCardChanged="() => {}"
                />
              </div>
            </div>
          </el-collapse-item>
          <!-- endregion -->
        </el-collapse>
      </div>
      <div class="edit-booking-buttons">
        <el-button
          type="default"
          class="back"
          @click="onSalesOrderEditionCancelled"
        >
          {{ $t("COMMON.GO_BACK") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, Button, CollapseItem } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import CreditCardSelector from "@/components/CreditCardSelector.vue";
import BookingCard from "../partials/pendingBookings/BookingCard.vue";
import EditBookingForm from "../partials/EditBookingForm.vue";
import EditBookingCustomer from "../partials/form/EditBookingCustomer.vue";

export default {
  layout: "DashboardLayout",

  components: {
    EditBookingCustomer,
    EditBookingForm,
    BookingCard,
    CreditCardSelector,
    [Collapse.name]: Collapse,
    [Button.name]: Button,
    [CollapseItem.name]: CollapseItem,
  },

  mixins: [alertLeave],

  props: {
    salesOrderId: {
      type: [String, Number],
      required: false,
    },
  },

  data() {
    return {
      selectedCard: null,
      salesOrder: null,
      customer: null,
      formErrors: null,
      loading: false,
      customerStep: false,
      selectedBooking: null, // L'identifiant de la reservation qu'on est entrain de modifier.
    };
  },

  async created() {
    await this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this._get();
      } catch (error) {
        console.log(error);

        this.$notify({ type: "danger", message: `Erreur imprévue` });
      } finally {
        this.loading = false;
      }
    },

    async _get() {
      await this.$store.dispatch("salesOrders/get", this.salesOrderId);
      this.salesOrder = this.$store.getters["salesOrders/salesOrder"];

      if (this.salesOrder.recipient) {
        this.customerStep = false;
        await this.$store.dispatch(
          "customers/get",
          this.salesOrder.recipient.id
        );
        this.customer = this.$store.getters["customers/customer"];
      } else {
        this.customerStep = true;
      }
    },

    async onEditBookingCustomerDone(updatedCustomer, isNewCustomer) {
      if (isNewCustomer) {
        this.loading = true;
        try
        {
          await this.updateSalesOrderCustomer(updatedCustomer);
          await this._get();

          this.onEditBookingCustomerCancelled();
          this.$emit("customerUpdated");
        } catch (error) {
          console.log(error);

          this.$notify({ type: "danger", message: `Erreur imprévue` });
        } finally {
          this.loading = false;
        }
      } else {
        this.customer = updatedCustomer;
        this.onEditBookingCustomerCancelled();
        this.$emit("customerUpdated");
      }
    },

    async updateSalesOrderCustomer(newCustomer) {
      // mise a jour de la commande avec le nouveau client
      this.salesOrder.recipient = {
        type: newCustomer.type,
        id: newCustomer.id,
      };
      this.salesOrder.relationshipNames.push("recipient");

      // mise a jout des billing data
      this.salesOrder.billing_address =
        newCustomer.billing_address ?? newCustomer.billing_address;
      this.salesOrder.billing_city =
        newCustomer.billing_city ?? newCustomer.billing_city;
      this.salesOrder.billing_company_name =
        newCustomer.billing_company_name ?? newCustomer.billing_company_name;
      this.salesOrder.billing_country =
        newCustomer.billing_country ?? newCustomer.billing_country;
      this.salesOrder.billing_email =
        newCustomer.billing_email ?? newCustomer.billing_email;
      this.salesOrder.email = newCustomer.email ?? newCustomer.email;
      this.salesOrder.billing_entity_type =
        newCustomer.billing_entity_type ?? newCustomer.billing_entity_type;
      this.salesOrder.billing_firstname =
        newCustomer.billing_firstname ?? newCustomer.billing_firstname;
      this.salesOrder.billing_lastname =
        newCustomer.billing_lastname ?? newCustomer.billing_lastname;
      this.salesOrder.billing_state =
        newCustomer.billing_state ?? newCustomer.billing_state;
      this.salesOrder.billing_zipcode =
        newCustomer.billing_zipcode ?? newCustomer.billing_zipcode;

      await this.$store.dispatch(
        "salesOrders/update",
        cloneDeep(this.salesOrder)
      );

      // mise a jour des bookings avec le nouveau client
      for (let item of this.salesOrder.items) {
        let booking = cloneDeep(item.salesOrderable);
        booking.customer = {
          type: newCustomer.type,
          id: newCustomer.id,
        };
        booking.relationshipNames.push("customer");
        await this.$store.dispatch("bookings/update", booking);
      }

      this.customer = null ;
    },

    onEditBookingCustomerCancelled() {
      this.customerStep = false;
    },

    editBooking(booking) {
      this.selectedBooking = booking;
    },

    onBookingEditionCancelled() {
      this.selectedBooking = null;
    },

    onSalesOrderEditionCancelled() {
      this.$emit("onSalesOrderEditionCancelled");
    },

    onBookingEditionDone(booking, status) {
      this.$emit("onViewBooking", booking, status);
      this.$emit("bookingUpdated", booking);
      this.onBookingEditionCancelled();
      this.get();
    },
  },
};
</script>
