export const CUSTOMER_TYPE_INDIVIDUAL = "INDIVIDUAL";
export const CUSTOMER_TYPE_COMPANY = "COMPANY";

/**
 * Indique que l'ajout se fait depuis le formulaire de réservation.
 */
export const CUSTOMER_FORM_TYPE_BOOKING = 'BOOKING';

/**
 * Indique que l'ajout se fait depuis le formulaire du module client.
 */
export const CUSTOMER_FORM_TYPE_CLASSIC = 'CLASSIC';

export const CUSTOMER_SEX_MALE = "MALE";

export const CUSTOMER_SEX_FEMALE = "FEMALE";

export const CUSTOMER_SEX_OTHERS = "OTHERS";

export const CUSTOMER_SEX_OPTIONS = [
    CUSTOMER_SEX_MALE,
    CUSTOMER_SEX_FEMALE,
    CUSTOMER_SEX_OTHERS
];