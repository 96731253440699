export const BOOKING_FORM_TYPE_DEFAULT = "DEFAULT";
export const BOOKING_FORM_TYPE_LAND = "LAND";
export const BOOKING_FORM_TYPE_PROPERTY = "PROPERTY";

export const BOOKING_FORM_TYPE_READY_TO_CAMP = "READY_TO_CAMP";
export const BOOKING_FORM_TYPE_SEASONAL = "SEASONAL";

export const bookingFormTypeOptions = [
  BOOKING_FORM_TYPE_DEFAULT,
  BOOKING_FORM_TYPE_LAND,
  BOOKING_FORM_TYPE_PROPERTY,
  BOOKING_FORM_TYPE_SEASONAL,
  BOOKING_FORM_TYPE_READY_TO_CAMP
];