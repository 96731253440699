<template>
  <div class="section">
    <dl class="row">
      <dt>
        {{ item.unit_price }} CAD ( x
        {{ $t("COMMON.UNIT_DAYS", { days: item.quantity }) }} )
      </dt>
      <dd>{{ item.pricing.subtotal }} CAD</dd>
    </dl>
    <dl class="row">
      <dt>TPS</dt>
      <dd>5.08 CAD</dd>
    </dl>
    <dl class="row">
      <dt>TVQ</dt>
      <dd>12,14 CAD</dd>
    </dl>
    <dl class="row subtotal">
      <dt>{{ $t("COMMON.SUBTOTAL") }}</dt>
      <dd>{{ item.pricing.subtotal }} CAD</dd>
    </dl>
  </div>
</template>
<script>
import { Carousel, CarouselItem, Tag, Button } from "element-ui";
import { SPOT_STATUS_ACTIVE } from "@/constants/spots";
import BookingCard from "./BookingCard.vue";

export default {
  name: "order-item-summary",
  components: {},
  props: {
    item: {
      type: Object,
      default: null,
      description: "SalesOrderItem object",
    },
  },
  data() {
    return {};
  },
};
</script>
